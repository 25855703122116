import {useQuery} from '@tanstack/react-query'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetKeyPortfolio, IKeyPortfolio} from '@api/key/portfolio/getKeyPortfolio'

const useQueryGetKeyProfile = (id: string, enabled: boolean = true) => {
    const router = useRouter()

    const {data, refetch, isLoading, isFetching, isSuccess} = useQuery({
        queryKey: QueryCacheKeys.portfolio.getKeyProfile(id),
        enabled: enabled && !!id,
        staleTime: 5000,
        refetchInterval: PORTFOLIO_REFRESH_INTERVAL,
        queryFn: async () => {
            const {data} = await apiGetKeyPortfolio(id)
            if (data) return data
            return {} as IKeyPortfolio
        },
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading, isFetching, isSuccess}
}

export default useQueryGetKeyProfile
