import {useInfiniteQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetLeagueShowPrevious} from '@api/rank/league/getLeaguePrevious'
import {useTranslation} from 'next-i18next'

const PAGE_SIZE = 30
export const useQueryGetPreviousLeagueSidebar = (enabled?: boolean) => {
    const {t} = useTranslation()
    const {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isFetching, isSuccess} = useInfiniteQuery({
        enabled: enabled ?? true,
        queryKey: QueryCacheKeys.leaderboard.getLeagueShowPrevious(),
        staleTime: 120 * 1000,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: true,
        retryDelay: 2000,
        queryFn: async ({pageParam}) => {
            const {data} = await apiGetLeagueShowPrevious(pageParam)

            return data
        },
        getNextPageParam: lastPage => {
            return lastPage?.leagues?.length > 0 ? lastPage?.leagues[lastPage?.leagues?.length - 1]?.end_at : 0
        },
    })

    const isEmpty = data?.pages?.at(0)?.leagues?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.leagues?.length === 0)

    useDidMountEffect(() => {
        refetch()
    }, [t])

    return {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd, isFetching, isSuccess}
}
