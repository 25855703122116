import {useInfiniteQuery} from '@tanstack/react-query'
import {RankKind} from '@feature/leaderboard/pages/ScreenMain/MainLeaderboardHeaders/MainLeaderboardOptionContainer'
import {apiGetLeagueSubscriberRank} from '@api/rank/rank'
import {apiGetLeagueRankList} from '@api/rank/league/league'
import {isNotEmpty} from '@util/strings'
import {useLoginStore} from '@store/LoginStore'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useRouter} from 'next/router'

const PAGE_SIZE = 30
const useQueryRankLeague = (currentLeagueID?: string, rankKind?: RankKind) => {
    const router = useRouter()
    const myId = useLoginStore(state => state.id)

    const {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isFetching, isSuccess} = useInfiniteQuery({
        enabled: isNotEmpty(currentLeagueID),
        queryKey: QueryCacheKeys.leaderboard.getRankLeague(currentLeagueID, rankKind, myId, router?.locale),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: 120 * 1000,
        staleTime: 120 * 1000,
        cacheTime: 120 * 1000,
        retry: true,
        retryDelay: 2000,
        queryFn: async ({pageParam}) => {
            if (rankKind === 'following') {
                const {data} = await apiGetLeagueSubscriberRank(currentLeagueID, router?.locale)
                return data
            }
            const {data} = await apiGetLeagueRankList(currentLeagueID, pageParam, PAGE_SIZE, router?.locale)
            return data
        },
        getNextPageParam: (lastPage, allPages) => (allPages ? allPages.reduce((a, b) => a + b?.length || 0, 0) : 0),
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    return {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd, isFetching, isSuccess}
}

export default useQueryRankLeague
