import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {
    apiGetDepositAddress,
    IAddress,
    TGetDepositAddressKind,
} from '@api/user/account/wallet/deposit/getDepositAddress'
import {isNotEmpty} from '@util/strings'

const useQueryGetDepositAddressCrypto = (kind: TGetDepositAddressKind, chain?: string) => {
    const {data} = useQuery({
        queryKey: QueryCacheKeys.wallet.getDepositAddress(kind, chain),
        queryFn: async () => {
            const {data} = await apiGetDepositAddress(kind, chain)
            if (data) return data
            return {} as IAddress
        },
        enabled: isNotEmpty(chain),
    })

    return {data}
}

export default useQueryGetDepositAddressCrypto
