import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import {apiGetKeyHistory, HistorySortType, HistorySortTypes, IHistory} from '@api/key/portfolio/getHistory'
import {useMemo} from 'react'

const PAGE_SIZE = 50
const useQueryGetHistory = (keyId: string, sort: HistorySortType = HistorySortTypes.ALL, isLeader: boolean = false) => {
    const {data, refetch, isLoading, isFetching, isSuccess, isFetchingNextPage, fetchNextPage} = useInfiniteQuery({
        queryKey: QueryCacheKeys.portfolio.getKeyHistory(keyId, sort, isLeader),
        enabled: isNotEmpty(keyId),
        staleTime: 60 * 1000,
        queryFn: async ({pageParam = 0}) => {
            const {data, error} = await apiGetKeyHistory(keyId, pageParam, sort, isLeader)
            if (data) return data
            return [] as IHistory[]
        },
        getNextPageParam: (lastPage, allPages) => lastPage && lastPage[lastPage.length - 1]?.created_at,
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    const history = useMemo(() => {
        return data?.pages?.reduce<IHistory[]>((prevRanks, currRanks) => {
            return prevRanks.concat(currRanks)
        }, [])
    }, [data])

    return {
        data,
        history,
        refresh: refetch,
        isLoading,
        isFetching,
        isSuccess,
        isFetchingNextPage,
        fetchNextPage,
        isReachingEnd,
    }
}

export default useQueryGetHistory
