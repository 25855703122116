import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetLeaderInfo, ILeaderInfo} from '@api/key/leaderfolio/getLeaderInfo'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'
import {isNotEmpty} from '@util/strings'

const useQueryGetLeaderInfo = (key_id: string, enabled: boolean = true, isInterval: boolean = true) => {
    return useQuery({
        queryKey: QueryCacheKeys.leaderfolio.getLeaderInfo(key_id),
        enabled: enabled && isNotEmpty(key_id),
        staleTime: 10000,
        cacheTime: 10000,
        refetchInterval: isInterval ? PORTFOLIO_REFRESH_INTERVAL : false,
        queryFn: async () => {
            const {data} = await apiGetLeaderInfo(key_id)
            if (data) return data
            return {} as ILeaderInfo
        },
        refetchOnWindowFocus: false,
    })
}

export default useQueryGetLeaderInfo
