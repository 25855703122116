import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetLeaderDetail} from '@api/copy/leader/detail/getLeaderDetail'
import format from 'date-fns/format'
import {subMonths} from 'date-fns'
import {getDateFromYYYYMMDD} from '@util/dates'

const PAGE_SIZE = 1
const useQueryGetLeaderDetailAll = (id: string, type: string, when: number, before) => {
    const {data, refetch, isLoading, isFetching, isSuccess, isError, isFetchingNextPage, fetchNextPage} =
        useInfiniteQuery({
            queryKey: QueryCacheKeys.leaderfolio.getLeaderDetailAll(id, type, before),
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60,
            queryFn: async ({pageParam}) => {
                const baseline = pageParam
                    ? format(subMonths(getDateFromYYYYMMDD(Number(`${pageParam}`)), 1), 'yyyyMM')
                    : undefined
                const {data, error} = await apiGetLeaderDetail(id, type, when, baseline || before)
                return data
            },
            getNextPageParam: (lastPage, allPages) => {
                return lastPage?.detail_data?.at(-1)?.when
            },
            enabled: !!id,
            refetchOnWindowFocus: false,
        })

    const isEmpty = data?.pages?.at(0)?.detail_data?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.detail_data?.length < PAGE_SIZE)

    return {
        data,
        refresh: refetch,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        isReachingEnd,
        isFetching,
        isSuccess,
        isError,
    }
}

export default useQueryGetLeaderDetailAll
