import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {getCopierMineBot} from '@api/copy/copier/dashboard/getCopierMineBot'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'

const useQueryGetCopierMineBot = (enabled?: boolean) => {
    const router = useRouter()

    const {data, refetch, isLoading, isFetching, isSuccess} = useQuery({
        queryKey: QueryCacheKeys.copyfolio.getUserCopierMineBot(),
        staleTime: 5000,
        cacheTime: 5000,
        refetchInterval: PORTFOLIO_REFRESH_INTERVAL,
        queryFn: async () => {
            const {data} = await getCopierMineBot()
            return data
        },
        enabled: enabled,
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading, isFetching, isSuccess}
}

export default useQueryGetCopierMineBot
