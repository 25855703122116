import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {getPaymentTransactions} from '@api/user/account/wallet/getWalletTransaction'
import {useLoginStore} from '@store/LoginStore'
import {isNotEmpty} from '@util/strings'

const PAGE_SIZE = 30

const useQueryPaymentTransaction = (action?: string) => {
    const myId = useLoginStore(state => state.id)

    const {data, isLoading, isFetchingNextPage, fetchNextPage} = useInfiniteQuery({
        queryKey: QueryCacheKeys.wallet.getTransaction(myId, action),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: 60 * 1000,
        enabled: isNotEmpty(action),
        queryFn: async ({pageParam}) => {
            const {data} = await getPaymentTransactions(action, PAGE_SIZE, pageParam)
            return data
        },
        getNextPageParam: (lastPage, allPages) => allPages.reduce((a, b) => a + b?.length, 0),
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    return {data, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd}
}

export default useQueryPaymentTransaction
