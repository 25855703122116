import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useLoginStore} from '@store/LoginStore'
import {apiGetWithdrawHistory} from '@api/user/account/wallet/withdraw/getWithdrawHistory'

const PAGE_SIZE = 30

const useQueryWithdrawHistory = () => {
    const myId = useLoginStore(state => state.id)

    const {data, isLoading, isFetchingNextPage, fetchNextPage} = useInfiniteQuery({
        queryKey: QueryCacheKeys.wallet.getWithdrawHistory(myId),
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
        queryFn: async ({pageParam}) => {
            const before = Math.floor(new Date(pageParam).getTime() / 1000)
            const {data} = await apiGetWithdrawHistory(10)
            return data
        },
        getNextPageParam: lastPage => (lastPage?.length > 0 ? lastPage[lastPage.length - 1].created_at : 0),
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    return {data, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd}
}

export default useQueryWithdrawHistory
