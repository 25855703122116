import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetKeyCopyfolio} from '@api/key/copyfolio/getKeyCopyfolio'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'
import {isNotEmpty} from '@util/strings'

const useQueryGetCopierInfo = (id: string, enabled?: boolean) => {
    const router = useRouter()

    const {data, refetch, isLoading, isSuccess, isFetching} = useQuery({
        queryKey: QueryCacheKeys.copyfolio.getCopierInfo(id),
        refetchInterval: PORTFOLIO_REFRESH_INTERVAL,
        staleTime: 5000,
        cacheTime: 5000,
        queryFn: async () => {
            const {data, error} = await apiGetKeyCopyfolio(id)
            return data
        },
        enabled: enabled ? enabled : isNotEmpty(id),
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading, isSuccess, isFetching}
}

export default useQueryGetCopierInfo
