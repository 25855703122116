import {useQuery} from '@tanstack/react-query'
import {apiGetLeagueShow} from '@api/rank/league/getLeagueShow'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useRouter} from 'next/router'

export const useQueryGetShowLeagueSidebar = (enabled?: boolean) => {
    const router = useRouter()

    const {data, refetch, isLoading, isFetching, isSuccess} = useQuery({
        enabled: enabled ?? true,
        queryKey: QueryCacheKeys.leaderboard.getLeagueShow(router?.locale),
        staleTime: 60 * 1000,
        cacheTime: 60 * 1000,
        keepPreviousData: true,
        retry: true,
        retryDelay: 2000,
        queryFn: async () => {
            const {data} = await apiGetLeagueShow(router?.locale)
            return data
        },
        refetchOnWindowFocus: false,
    })

    return {data, refetch, isLoading, isFetching, isSuccess}
}
