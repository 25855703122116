import {useLoginStore} from '@store/LoginStore'
import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import {apiGetBookmarkMatchList} from '@api/copy/leader/bookmark/getBookmarkMatchList'

const useQueryGetBookmarkList = () => {
    const loginId = useLoginStore(state => state.id)
    const {data, refetch, isLoading} = useQuery({
        queryKey: QueryCacheKeys.bookmark.getBookmarkList(loginId),
        enabled: isNotEmpty(loginId),
        staleTime: 60 * 1000,
        cacheTime: 60 * 1000,
        retry: true,
        retryDelay: 2000,
        queryFn: async () => {
            const {data, error} = await apiGetBookmarkMatchList()
            if (data) return data
            return []
        },
    })
    return {data, refetch, isLoading}
}

export default useQueryGetBookmarkList
