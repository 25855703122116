import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {DayDuration} from '@type/time'
import {apiGetCopierGraphForKey, apiGetCopierGraphForUser} from '@api/user/profile/copy/getCopierGraph'

export type GraphTYPE = 'user' | 'key'

const useQueryGetCopierChart = (type: GraphTYPE, id: string, duration?: DayDuration.Month | DayDuration.Lifetime) => {
    const router = useRouter()

    const {data, refetch, isLoading} = useQuery({
        queryKey: QueryCacheKeys.copyfolio.getUserCopyChart(id, duration),
        staleTime: 100000,
        queryFn: async () => {
            if (type === 'key') {
                const {data, error} = await apiGetCopierGraphForKey(id, duration)
                return data
            } else {
                const {data, error} = await apiGetCopierGraphForUser(id, duration)
                return data
            }
        },
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading}
}

export default useQueryGetCopierChart
