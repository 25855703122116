import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {getLeaderMyKeys} from '@api/copy/leader/getLeaderMyKeys'

const useQueryGetLeaderMyKeys = (intervalTime?: number, enabled?: boolean) => {
    const router = useRouter()

    const {data, refetch, isLoading, isFetching, isSuccess} = useQuery({
        enabled: enabled,
        queryKey: QueryCacheKeys.leaderfolio.getLeaderMyKeys(),
        staleTime: 5000,
        refetchInterval: intervalTime ?? false,
        queryFn: async () => {
            const {data} = await getLeaderMyKeys()
            return data
        },
        refetchOnWindowFocus: !intervalTime ?? false,
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading, isFetching, isSuccess}
}

export default useQueryGetLeaderMyKeys
