import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetDepositCurrencies, IDepositData} from '@api/user/account/wallet/deposit/getDepositCurrencies'

const useQueryDepositCurrencies = () => {
    const {data} = useQuery({
        queryKey: QueryCacheKeys.wallet.getDepositCurrencies(),
        queryFn: async () => {
            const {data} = await apiGetDepositCurrencies()
            if (data) return data
            return {} as IDepositData
        },
    })

    return {data}
}

export default useQueryDepositCurrencies
