import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {getUserWallets} from '@api/user/account/wallet/getUserWallets'
import {IWallets} from '@type/profile'

const useQueryUserWallets = () => {
    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.wallet.getUserWallets(),
        staleTime: 5000,
        queryFn: async () => {
            const {data} = await getUserWallets()
            if (data) return data
            return {} as IWallets
        },
    })

    return {data, refresh: refetch}
}

export default useQueryUserWallets
