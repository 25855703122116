import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetProBoot} from '@api/user/account/pro/getProBoot'

const useQueryGetProBoot = () => {
    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.pro.getProBoot(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        staleTime: 1000000,
        queryFn: async () => {
            const {data} = await apiGetProBoot()
            return data
        },
    })

    return {data, refetch}
}

export default useQueryGetProBoot
