import {useQuery} from '@tanstack/react-query'
import {isNotEmpty} from '@util/strings'
import {apiGetMeRank, MeRankData} from '@api/user/profile/meRank'
import {useLoginStore} from '@store/LoginStore'
import {QueryCacheKeys} from '@hook/query/queryKeys'

const useQueryMyRank = () => {
    const myId = useLoginStore(state => state.id)

    const {data, refetch} = useQuery({
        enabled: isNotEmpty(myId),
        queryKey: QueryCacheKeys.leaderboard.getMyRank(myId),
        staleTime: 120 * 1000,
        cacheTime: 120 * 1000,
        retry: true,
        retryDelay: 2000,
        queryFn: async () => {
            const {data} = await apiGetMeRank()
            if (data) return data
            return {} as MeRankData
        },
    })

    return {data, refetch}
}

export default useQueryMyRank
