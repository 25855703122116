import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetMyBots, BotType, MyBots} from '@api/bot/bot'
import {useLoginStore} from '@store/LoginStore'
import {isNotEmpty} from '@util/strings'

const useQueryMyBots = (botType?: BotType) => {
    const userId = useLoginStore(state => state.id)
    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.bot.getMyBots(botType, userId),
        enabled: isNotEmpty(userId),
        staleTime: 120 * 1000,
        cacheTime: 120 * 1000,
        queryFn: async () => {
            const {data} = await apiGetMyBots(botType)
            if (data) return data
            return {} as MyBots.Res
        },
    })
    return {data, refetch}
}

export default useQueryMyBots
