import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetInvoices} from '@api/user/account/invoice/getInvoices'
import {useLoginStore} from '@store/LoginStore'

const useQueryGetInvoices = () => {
    const loginUserId = useLoginStore(state => state.id)
    const router = useRouter()

    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.invoice.getInvoiceList(loginUserId),
        staleTime: 5000,
        queryFn: async () => {
            const {data} = await apiGetInvoices()
            return data
        },
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch}
}

export default useQueryGetInvoices
