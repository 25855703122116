import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetCopierDailyPnl} from '@api/copy/copier/dailyPnl/getDailyPnl'
import {isNotEmpty} from '@util/strings'

const PAGE_SIZE = 30
const useQueryGetCopierDailyPnl = (botId?: string, size?: number) => {
    const {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isFetching, isSuccess} = useInfiniteQuery({
        queryKey: [QueryCacheKeys.copyfolio.getCopierDailyPnl(botId ?? ''), size],
        refetchInterval: 120 * 1000,
        staleTime: 120 * 1000,
        queryFn: async ({pageParam}) => {
            const {data, error} = await apiGetCopierDailyPnl({
                bot_id: botId,
                limit: size || PAGE_SIZE,
                before: pageParam,
            })
            return data
        },
        getNextPageParam: (lastPage, allPages) => {
            const length = allPages.reduce((a, b) => a + b?.length || 0, 0)
            if (length < PAGE_SIZE) return undefined
            else return lastPage?.at(-1)?.day
        },
        enabled: isNotEmpty(botId),
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || ((data && data?.pages?.at(-1)?.length < size) ?? PAGE_SIZE)

    return {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd, isSuccess, isFetching}
}

export default useQueryGetCopierDailyPnl
