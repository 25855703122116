import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import {IHistory} from '@api/key/portfolio/getHistory'
import {useMemo} from 'react'
import {apiGetLeaderHistory} from '@api/key/leaderfolio/getLeaderHistory'

const PAGE_SIZE = 50
const useQueryGetLeaderHistory = (keyId: string) => {
    const {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isFetching, isSuccess} = useInfiniteQuery({
        queryKey: QueryCacheKeys.leaderfolio.getLeaderHistory(keyId),
        enabled: isNotEmpty(keyId),
        staleTime: 60 * 1000,
        queryFn: async ({pageParam = 0}) => {
            const {data, error} = await apiGetLeaderHistory(keyId, pageParam, PAGE_SIZE)
            if (data) return data
            return [] as IHistory[]
        },
        getNextPageParam: (lastPage, allPages) => allPages?.reduce((a, b) => a + b?.length, 0),
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    const history = useMemo(() => {
        return data?.pages?.reduce<IHistory[]>((prevRanks, currRanks) => {
            return prevRanks.concat(currRanks)
        }, [])
    }, [data])

    return {
        data,
        history,
        refresh: refetch,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        isReachingEnd,
        isFetching,
        isSuccess,
    }
}

export default useQueryGetLeaderHistory
