import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {getLeaderKeysForStartCopy, ICopyLeaderStartCopy} from '@api/copy/leader/getLeaderKeysForStartCopy'

const useQueryGetLeaderKeysStartCopy = (userId: string) => {
    const router = useRouter()

    const {data, refetch, isLoading} = useQuery({
        queryKey: QueryCacheKeys.leaderfolio.getLeaderKeysForStartCopy(userId),
        staleTime: 5000,
        queryFn: async () => {
            const {data} = await getLeaderKeysForStartCopy(userId)
            if (data) return data
            return [] as ICopyLeaderStartCopy[]
        },
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading}
}

export default useQueryGetLeaderKeysStartCopy
