import {useQuery} from '@tanstack/react-query'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetLeaderJoinableKey} from '@api/copy/leader/getJoinableKey'

const useQueryGetLeaderJoinableKey = () => {
    const router = useRouter()

    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.leaderfolio.getLeaderJoinableKey(),
        staleTime: 5000,
        queryFn: async () => {
            const {data} = await apiGetLeaderJoinableKey()
            return data
        },
    })

    return {data, refresh: refetch}
}

export default useQueryGetLeaderJoinableKey
