import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetLeaderDetail} from '@api/copy/leader/detail/getLeaderDetail'

const useQueryGetLeaderDetail = (id: string, type: string, when: number) => {
    const {data, refetch, isLoading, isFetching, isSuccess, isError} = useQuery({
        queryKey: QueryCacheKeys.leaderfolio.getLeaderDetail(id, type, when),
        staleTime: 1000 * 60,
        cacheTime: 1000 * 60,
        queryFn: async () => {
            const {data} = await apiGetLeaderDetail(id, type, when)
            return data
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
    })

    return {data, refresh: refetch, isLoading, isFetching, isSuccess, isError}
}

export default useQueryGetLeaderDetail
