import {useLocalStorage} from 'usehooks-ts'
import {KEY_PORTFOLIO_CHART_PERIOD_KEY, USER_PORTFOLIO_CHART_PERIOD_KEY} from '@constant/key'

export const ChartPeriodKeys = {
    UserPortfolioChartKey: USER_PORTFOLIO_CHART_PERIOD_KEY,
    KeyPortfolioChartKey: KEY_PORTFOLIO_CHART_PERIOD_KEY,
} as const
export type ChartPeriodKeyType = typeof ChartPeriodKeys[keyof typeof ChartPeriodKeys]
export const GraphPeriodOption = {
    Day: '1',
    Week: '7',
    Month: '30',
    ThreeMonth: '90',
    SixMonth: '180',
    Year: '365',
    All: '1000',
} as const
export type GraphPeriodOptionType = typeof GraphPeriodOption[keyof typeof GraphPeriodOption]

export default function useChartPeriod(key?: ChartPeriodKeyType) {
    const [chartPeriod, setChartPeriod] = useLocalStorage<GraphPeriodOptionType>(key, GraphPeriodOption.All)

    return {chartPeriod, setChartPeriod}
}
