import {useQuery} from '@tanstack/react-query'
import {apiGetSidebarKeyID, ISidebar} from '@api/user/sidebar/getSidebar'
import {PORTFOLIO_REFRESH_INTERVAL} from '@constant/refreshInterval'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import useSnackbar from '@hook/useSnackbar'

const useQueryGetKeySidebar = (id: string, enabled: boolean = true) => {
    const router = useRouter()
    const {showErrorSnackbar} = useSnackbar()

    return useQuery({
        queryKey: QueryCacheKeys.portfolio.getKeySidebar(id, router?.locale),
        enabled: enabled && isNotEmpty(id),
        refetchInterval: enabled ? PORTFOLIO_REFRESH_INTERVAL : false,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const {data, error} = await apiGetSidebarKeyID(id, {cookie: {language: router?.locale}})
            if (data) return data
            if (error) {
                showErrorSnackbar(error)
                return {} as ISidebar
            }
            return {} as ISidebar
        },
    })
}

export default useQueryGetKeySidebar
