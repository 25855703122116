import {useQuery} from '@tanstack/react-query'
import {CURRENCY_REFRESH_INTERVAL} from '@constant/refreshInterval'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {fetchCurrencies} from '@api/core/currency/currency'

const useQueryGetCurrencys = (enabled: boolean = false) => {
    const router = useRouter()

    const {data, refetch, isLoading, isError} = useQuery({
        queryKey: QueryCacheKeys.currency.getList(),
        enabled: enabled,
        staleTime: 100000,
        refetchInterval: CURRENCY_REFRESH_INTERVAL,
        queryFn: async () => {
            const {data} = await fetchCurrencies()
            return data
        },
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading, isError}
}

export default useQueryGetCurrencys
