import {useQuery} from '@tanstack/react-query'
import {apiGetProfile} from '@api/user/profile/getProfile'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {IProfile} from '@type/profile'
import useSnackbar from '@hook/useSnackbar'
import {useTranslation} from 'next-i18next'

const useQueryGetUserProfile = (id: string, intervalTime?: number, enabled: boolean = true) => {
    const {t} = useTranslation()
    const {showErrorSnackbar} = useSnackbar()
    return useQuery({
        queryKey: QueryCacheKeys.portfolio.getUserProfile(id),
        enabled: enabled && !!id,
        refetchInterval: intervalTime ?? false,
        refetchOnWindowFocus: !intervalTime && false,
        keepPreviousData: true,
        retry: true,
        retryDelay: 2000,
        queryFn: async () => {
            const {data, error} = await apiGetProfile(id)
            if (data) return data
            if (error) {
                // showErrorSnackbar(error)
                showErrorSnackbar(t('error.code.5000'))
                return
            }
            return {} as IProfile
        },
    })
}

export default useQueryGetUserProfile
