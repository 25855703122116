import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import {apiGetLeaderCopiersAll} from '@api/copy/leader/getLeaderCopiersAll'

const PAGE_SIZE = 30
const useQueryGetLeaderCopierAll = (keyId: string) => {
    const {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isFetching, isSuccess} = useInfiniteQuery({
        queryKey: QueryCacheKeys.leaderfolio.getLeaderCopierAll(keyId),
        refetchInterval: 120 * 1000,
        staleTime: 120 * 1000,
        queryFn: async ({pageParam}) => {
            const {data, error} = await apiGetLeaderCopiersAll(keyId, PAGE_SIZE, pageParam)
            return data
        },
        getNextPageParam: (lastPage, allPages) => allPages.reduce((a, b) => a + b?.length || 0, 0),
        enabled: isNotEmpty(keyId),
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    return {data, refresh: refetch, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd, isFetching, isSuccess}
}

export default useQueryGetLeaderCopierAll
