import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetCopyTradingTerms, ICopyTradingTerms} from '@api/copy/copier/terms/apiGetCopyTradingTerms'
import {useRouter} from 'next/router'

const useQueryGetCopyTradingTerms = (enabled: boolean = false) => {
    const router = useRouter()
    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.copyfolio.getCopyTradingTerms(router?.locale),
        staleTime: 120 * 1000,
        enabled: enabled,
        queryFn: async ({pageParam}) => {
            const {data, error} = await apiGetCopyTradingTerms()
            if (data) return data
            return {} as ICopyTradingTerms
        },
    })

    return {data, refetch}
}

export default useQueryGetCopyTradingTerms
