import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import {DayDuration} from '@type/time'
import {apiGetKeyGraph, IKeyGraph} from '@api/user/profile/getKeyGraph'
import {GraphPeriodOptionType} from '@hook/useChartPeriod'

const useQueryGetKeyGraph = (id: string, duration: GraphPeriodOptionType, enabled: boolean = true) => {
    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.portfolio.getKeyGraph(id, duration),
        staleTime: 60 * 1000,
        queryFn: async () => {
            const {data} = await apiGetKeyGraph(id, duration)
            if (data) return data
            return [] as IKeyGraph[]
        },
        enabled: enabled && isNotEmpty(id),
    })

    return {graph: data, refresh: refetch}
}

export default useQueryGetKeyGraph
