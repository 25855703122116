import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetProPayConfirmStatus} from '@api/user/account/pro/getProPayConfirmStatus'
import {isNotEmpty} from '@util/strings'

const useQueryGetProPayConfirmStatus = (id: string) => {
    const {data, refetch} = useQuery({
        enabled: isNotEmpty(id),
        queryKey: QueryCacheKeys.pro.getProPayConfirmStatus(),
        staleTime: 5000,
        refetchInterval: 5000,
        queryFn: async () => {
            const {data} = await apiGetProPayConfirmStatus(id, 'binance-pay')
            return data
        },
    })

    return {data, refetch}
}

export default useQueryGetProPayConfirmStatus
