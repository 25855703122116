import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useMemo} from 'react'
import {isNotEmpty} from '@util/strings'
import {apiGetKeyMonthlyPnl} from '@api/key/portfolio/getKeyMonthlyPnl'

const PAGE_SIZE = 30
const useQueryGetMonthlyPnl = (keyId: string) => {
    const {data, refetch, isLoading, isFetchingNextPage, fetchNextPage} = useInfiniteQuery({
        queryKey: QueryCacheKeys.portfolio.getMonthlyPnl(keyId),
        refetchInterval: 120 * 1000,
        staleTime: 120 * 1000,
        queryFn: async ({pageParam}) => {
            const {data, error} = await apiGetKeyMonthlyPnl({key_id: keyId, limit: PAGE_SIZE, before: pageParam})
            return data
        },
        getNextPageParam: (lastPage, allPages) => {
            const length = allPages.reduce((a, b) => a + b?.length || 0, 0)
            if (length < PAGE_SIZE) return undefined
            else return lastPage?.at(-1)?.day
        },
        enabled: isNotEmpty(keyId),
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    const pnlList = useMemo(() => {
        const list = []
        data?.pages?.map(page => page?.map(item => list.push(item)))
        return list
    }, [data])

    return {data: pnlList, refresh: refetch, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd}
}

export default useQueryGetMonthlyPnl
