import {useQuery} from '@tanstack/react-query'
import {apiGetMiniProfile} from '@api/user/profile/miniProfile'
import {QueryCacheKeys} from '@hook/query/queryKeys'

const useQueryUserMini = (user_id: string, enabled: boolean = false) => {
    return useQuery({
        enabled: enabled,
        queryKey: QueryCacheKeys.leaderboard.getUserMini(user_id),
        queryFn: () => apiGetMiniProfile(user_id),
    })
}
export default useQueryUserMini
