import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import {DayDuration} from '@type/time'
import {apiGetUserGraph, IUserGraph} from '@api/user/profile/getUserGraph'
import {GraphPeriodOptionType} from '@hook/useChartPeriod'

const useQueryGetUserGraph = (
    id: string,
    duration:
        | DayDuration.Week
        | DayDuration.Month
        | DayDuration.ThreeMonth
        | DayDuration.SixMonth
        | DayDuration.Year
        | DayDuration.Lifetime
        | GraphPeriodOptionType,
    enabled: boolean = true,
    permission?: boolean,
) => {
    const {data, refetch, isFetching, isSuccess, isLoading} = useQuery({
        queryKey: QueryCacheKeys.portfolio.getUserGraph(id, duration, permission),
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const {data} = await apiGetUserGraph(id, duration)
            if (data) return data
            return [] as IUserGraph[]
        },
        enabled: enabled && isNotEmpty(id),
    })

    return {chartGraphData: data, refresh: refetch, isFetching, isSuccess, isLoading}
}

export default useQueryGetUserGraph
