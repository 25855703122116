import {useInfiniteQuery} from '@tanstack/react-query'
import {useLoginStore} from '@store/LoginStore'
import {apiGetReferralPayment} from '@api/user/account/referral/getReferralPayment'

const PAGE_SIZE = 30
const useQueryReferralPayment = () => {
    const myId = useLoginStore(state => state.id)

    const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching, isSuccess} = useInfiniteQuery({
        queryKey: ['/referral-payment/', myId],
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: 30 * 1000,
        queryFn: async ({pageParam}) => {
            const {data} = await apiGetReferralPayment(myId, pageParam)
            return data
        },
        getNextPageParam: lastPage => {
            if (lastPage?.length < PAGE_SIZE) return undefined
            else return lastPage?.at(-1).timestamp
        },
    })

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    return {data, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd, hasNextPage, isFetching, isSuccess}
}

export default useQueryReferralPayment
