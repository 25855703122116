import {useInfiniteQuery} from '@tanstack/react-query'
import {apiGetRank, apiGetSubscriberRank} from '@api/rank/rank'
import {
    RankKind,
    RankPeriod,
    RankSortBy,
} from '@feature/leaderboard/pages/ScreenMain/MainLeaderboardHeaders/MainLeaderboardOptionContainer'
import {equalString} from '@util/strings'
import {useLoginStore} from '@store/LoginStore'
import {ExchangeType} from '@type/exchange'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useRouter} from 'next/router'

const PAGE_SIZE = 30
const useQueryRank = (
    filterExchange?: ExchangeType | string,
    filterPeriod?: RankPeriod,
    rankKind?: RankKind,
    sortBy?: RankSortBy,
) => {
    const router = useRouter()
    const myId = useLoginStore(state => state.id)

    const {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isFetching, isSuccess} = useInfiniteQuery({
        queryKey: QueryCacheKeys.leaderboard.getRank(
            filterExchange,
            filterPeriod,
            rankKind,
            sortBy,
            myId,
            router?.locale,
        ),
        refetchOnWindowFocus: false,
        refetchInterval: 120 * 1000,
        retry: true,
        retryDelay: 2000,
        staleTime: 120 * 1000,
        cacheTime: 120 * 1000,
        queryFn: ({pageParam}) => {
            if (rankKind === 'following') {
                return apiGetSubscriberRank(filterExchange, filterPeriod, sortBy, router?.locale)
            }
            return apiGetRank(filterExchange, filterPeriod, sortBy, pageParam ?? 0, PAGE_SIZE, router?.locale)
        },
        getNextPageParam: (lastPage, allPages) => allPages.reduce((a, b) => a + b.data?.length || 0, 0),
    })

    const isEmpty = data?.pages?.at(0)?.data?.length === 0
    const isReachingEnd =
        isEmpty || (data && data?.pages?.at(-1)?.data?.length < PAGE_SIZE) || equalString(rankKind, 'following')

    return {data, refetch, isLoading, isFetchingNextPage, fetchNextPage, isReachingEnd, isFetching, isSuccess}
}

export default useQueryRank
