import {useQuery} from '@tanstack/react-query'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetMyCopiableKeys} from '@api/copy/mine/getMyCopiableKeys'

const useQueryGetCopiableKeys = (exchange?: string, leader_key?: string) => {
    const router = useRouter()

    const {data, refetch} = useQuery({
        queryKey: QueryCacheKeys.portfolio.getCopiableKeys(exchange, leader_key || ''),
        staleTime: 5000,
        queryFn: async () => {
            const {data} = await apiGetMyCopiableKeys(exchange, leader_key)
            return data
        },
    })

    return {data, refresh: refetch}
}

export default useQueryGetCopiableKeys
