import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {isNotEmpty} from '@util/strings'
import {apiGetCopyHistory, ICopyHistory} from '@api/copy/copier/history/getCopyHistory'

const useQueryGetCopyHistory = (key_id: string) => {
    const {data, refetch, isLoading, isFetching, isSuccess} = useQuery({
        queryKey: QueryCacheKeys.copyfolio.getCopyHistory(key_id),
        queryFn: async ({pageParam}) => {
            const {data, error} = await apiGetCopyHistory(key_id)
            if (data) return data
            return [] as ICopyHistory[]
        },
        enabled: isNotEmpty(key_id),
    })

    return {data, refetch, isLoading, isFetching, isSuccess}
}

export default useQueryGetCopyHistory
